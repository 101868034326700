// openlayers読み込み
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import {transform} from 'ol/proj';
import {defaults as defaultControls, FullScreen, Rotate, ScaleLine} from 'ol/control';
import Select from 'ol/interaction/Select';
import {getCenter} from 'ol/extent';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';

// GeoJSON読み込み
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Fill, Stroke, Style, Text, Circle, RegularShape} from 'ol/style';

//openlayers拡張読み込み
import 'ol-ext/dist/ol-ext.css'
import Legend from 'ol-ext/legend/Legend';
import Legend_ctrl from 'ol-ext/control/Legend';
import Search_Feature from 'ol-ext/control/SearchFeature'

var cbox1 = document.getElementById("checkbox1");
var cbox2 = document.getElementById("checkbox2");

var style = new Style({
  fill: new Fill({
    //color: write
    color: 'rgba(255, 255, 255, 0.5)'
  }),
  stroke: new Stroke({
    color: '#000',
    width: 1
  }),
  text: new Text({
    font: '18px Calibri,sans-serif',
    fill: new Fill({
      color: '#666666'
    }),
    stroke: new Stroke({
      color: '#666666',
      width: 1
    })
  })
});

//This code is for loading the map of city region.
//Reference:http://nlftp.mlit.go.jp/ksj/gmlold/index.html
var vectorsource = new VectorSource({
    opacity: 0.4,
    url: './data/geojson/city.geojson',
    format: new GeoJSON()
});

var vectorLayer = new VectorLayer({
  source: vectorsource,
  style: function(feature) {
    style.getText().setText(feature.get('N03_004'));
    return style;
  }
});

//This code is for loading the map of ChiriMap.
//Reference:https://maps.gsi.go.jp/development/sample.html#sample-ol3
var openChiriMap = new TileLayer({
  opacity: 0.5,
  source: new XYZ({
    attributions: "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>©地理院タイル</a>",
    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
    projection: "EPSG:3857"
  })
});

//This code is for loading the source map of crashmap
var crashsource1 = new VectorSource({
    url: './data/geojson/death.geojson',
    format: new GeoJSON()
});

var crashsource2 = new VectorSource({
  opacity: 0.5,
  url: './data/geojson/injury.geojson',
  format: new GeoJSON()
});


var point_radius = 8.0;
var point_shape = 3;
var icon_size = 18;
var color_mvc = [51, 160, 44];
var color_pec = [227, 26, 28];
var color_svc = [207, 218, 84];
var color_trc = [255, 127, 0];


const predefinedStyles1 = {
    symbol: {
      symbolType: 'circle',
      size: icon_size,
      color:[
      'match',
      ["get","category"],
      121,color_mvc,
      101,color_pec,
      141,color_svc,
      color_trc
      ],
      rotateWithView: false,
    },
};

const predefinedStyles2 = {
  symbol: {
    symbolType: 'triangle',
    size: icon_size*1.2,
    color:[
    'match',
    ["get","category"],
    221,color_mvc,
    201,color_pec,
    241,color_svc,
    color_trc
    ],
    rotateWithView: false,
  },
};

//set the layer of crash types

const crashlayer1 = new WebGLPointsLayer({
  source: crashsource1 ,
  style: predefinedStyles1,
  opacity: 0.8
});

const crashlayer2 = new WebGLPointsLayer({
  source: crashsource2 ,
  style: predefinedStyles2,
  opacity: 0.8
});

var map = new Map({
  target: "map",
  renderer: ['canvas', 'dom'],
  layers: [openChiriMap,vectorLayer,crashlayer1,crashlayer2],
  controls: defaultControls({
    attributionOptions: ({
      collapsible: false,
    })
  }).extend([
    new FullScreen(),
    new Rotate,
    //new ScaleLine,
  ]),
  view: new View({
    projection: "EPSG:3857",
    center: transform([137.1562, 35.0823], "EPSG:4326", "EPSG:3857"),
    maxZoom: 18,
    minZoom: 5,
    zoom: 12,
    })
});

//Judging the visibility of different layers when the homepage is loaded
if (cbox1.checked==true) {
  crashlayer1.setVisible(true)
} else {
  crashlayer1.setVisible(false)
};

if (cbox2.checked==true) {
  crashlayer2.setVisible(true)
} else{
  crashlayer2.setVisible(false)
};

//Judging the visibility of different layers when the homepage is loaded

var highlightStyle = new Style({
  stroke: new Stroke({
    color: '#000',
    width: 3
  }),
  fill: new Fill({
    color: 'rgba(255,0,0,0)'
  }),
  text: new Text({
    font: '18px Calibri,sans-serif',
    fill: new Fill({
      color: '#FF0000'
    }),
    stroke: new Stroke({
      color: '000000',
      width: 1
    })
  })
});

//This function shows the selected feature in highlight style
var featureOverlay = new VectorLayer({
  source: new VectorSource(),
  map: map,
  style: function(feature) {
    highlightStyle.getText().setText(feature.get('N03_004'));
    return highlightStyle;
  }
});

//The variable of the selected feature in the polygon
var highlight;

//pixel is the point position of the mouse
var displayFeatureInfo = function(pixel) {
  //Detect feature of second layer that intersect a pixel on the viewport
  //The target layer is city region Map (vector data)
  var feature = map.forEachFeatureAtPixel(pixel, function(feature) {
    return feature;
    }, {
        //Set the target layer to find the selected object, 
        //if this process is omittted it will return a turple from the bottom to up.
        layerFilter: function(layer) {
            return layer === vectorLayer;
        }
    });

  var info = document.getElementById('info');
  if (feature) {
    info.innerHTML = 'マウス位置:' + feature.get('N03_004');
  } else {
    info.innerHTML = 'マウス位置：愛知県対象外';
  }

  //Highlight is the selected feature
  if (feature !== highlight) {
    if (highlight) {
      featureOverlay.getSource().removeFeature(highlight);
    }
    if (feature) {
      featureOverlay.getSource().addFeature(feature);
    }
    highlight = feature;
  }
};

//set the event of mouse moving
map.on('pointermove', function(evt) {
  if (evt.dragging) {
    return;
  }
  var pixel = map.getEventPixel(evt.originalEvent);
  displayFeatureInfo(pixel);
});

//set the event of mouse click
map.on('click', function(evt) {
  displayFeatureInfo(evt.pixel);
});

//set the visibility of layer1
cbox1.onclick = function(event) {
  var checkbox = event.target;
  if (checkbox.checked) {
    crashlayer1.setVisible(true)
  } else {
    crashlayer1.setVisible(false)
  }
};

//set the visibility of layer2
cbox2.onclick = function(event) {
  var checkbox = event.target;
  if (checkbox.checked) {
    crashlayer2.setVisible(true)
  } else {
    crashlayer2.setVisible(false)
  }
};

//**********************************
var legend = new Legend({ 
  title: '死亡事故',
  margin: 5
});

var legendCtrl = new Legend_ctrl({
  legend: legend,
  collapsed: false
});
map.addControl(legendCtrl);
//legend.on('select', function(e){ console.log(e) });
  // Add a new one
var legend2 = new Legend({ 
    title: '負傷事故', 
    margin: 5
  });
  map.addControl(new Legend_ctrl({
    legend: legend2,
    target: legendCtrl.element
  }));
  
  var form = { 車両相互:color_mvc, 人対車両:color_pec, 車両単独:color_svc, 列車:color_trc};
  for (var i in form) {
    legend.addItem({ 
      title: i, 
      typeGeom: 'Point',
      style: new Style({
        image: new Circle({
          radius: point_radius,
          fill: new Fill({ color: form[i]})
        })
      })
    });
    legend2.addItem({ 
      title: i, 
      typeGeom: 'Point',
      style: new Style({
        image: new RegularShape({
          radius: point_radius,
          points: point_shape,
          fill: new Fill({ color: form[i]})
        })
      })
    });
  }

  // Control Select
	var select = new Select({});
	map.addInteraction(select);

  // Set the control grid reference
	var search = new Search_Feature(
		{	//target: "N03_004": (The column name of attribute)
			source: vectorsource,
			property: "N03_004"
		});
	map.addControl (search);

  // Select feature when click on the reference index
	search.on('select', function(e)
  {	select.getFeatures().clear();
    select.getFeatures().push(e.search);
    var p = e.search.getGeometry().getExtent();
    map.getView().setZoom(13)
    map.getView().animate({center: getCenter(p)});
  });